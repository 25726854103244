<template>
  <v-card elevation="1">
    <v-card-text v-if="proposalRecord">
      <div>
        <v-chip
          v-if="proposalRecord.viewStatus == 0"
          class="mr-2"
          x-small
          color="#E57373"
          text-color="white"
          >NEW</v-chip
        >

        <v-chip
          x-small
          v-if="proposalRecord.viewStatus == 3"
          color="green"
          text-color="white"
          class="chip"
          >更新</v-chip
        >
        <!--
        <v-chip x-small v-if="proposalRecord.viewStatus == 1" class="chip"
          >既読</v-chip
        >-->
      </div>

      <div class="mt-1">
        <span class="mt-1 text-h6">
          {{ proposalRecord.title }}
        </span>
        <span v-if="proposalRecord.state == 2"> (下書き) </span>

        <a
          href="#"
          v-if="canEdit"
          v-on:click.prevent="onEditClick"
          style="color: gray"
          ><i class="fas fa-edit"></i
        ></a>
      </div>

      <div class="mt-1 text-body-1 proposal_content">
        {{ proposalRecord.content }}
      </div>

      <div class="d-flex flex-row mt-4">
        <span class="caption">提案者：</span>

        <span class="caption"> {{ proposalRecord.postUserName }}</span>

        <span class="caption ml-2">
          ({{ formatDateToDisplay(proposalRecord.create_at) }})
        </span>
      </div>

      <div class="d-flex flex-row mt-1">
        <span class="caption">担当G：</span>
        <div class="mr-3 caption">
          <span v-if="proposalRecord.groupCd">{{
            getGroupName(proposalRecord.groupCd)
          }}</span>
          <span v-else>未設定</span>
        </div>

        <span class="caption">状態：</span>
        <div class="caption">
          <span v-if="proposalRecord.workflowState">
            {{ getProposalWfStatusName(proposalRecord.workflowState) }}</span
          >
          <span v-else>未設定</span>
        </div>
      </div>

      <!-- 既読処理 -->
      <IntersectionObserver
        v-if="
          proposalRecord.viewStatus != 1 && !proposalRecord.read_event_fired
        "
        @intersect="onEntryIntersected(proposalRecord)"
      />
    </v-card-text>
    <v-card-text v-if="proposalRecord">
      <!-- コメントの表示 -->
      <v-sheet
        v-for="commentRec in proposalRecord.comments"
        elevation="0"
        rounded
        color="blue-grey lighten-5 my-1"
      >
        <div class="pa-2">
          <div class="comment_header font-weight-bold subtitle-2">
            {{ commentRec.commentUserName }}
            <span class="comment_time"
              >( {{ formatDateTimeToDisplay(commentRec.commentAt) }} )</span
            >
            <a
              v-if="checkCanEdit(commentRec)"
              href="#"
              class="ml-2"
              style="color: gray"
              v-on:click="onEditCommentClick(commentRec.id)"
              ><i class="fas fa-edit"></i
            ></a>
          </div>

          <div class="mt-1 mb-2 comment_content">
            <template v-for="pc in commentRec.contentForDisplay">
              <span v-if="pc.type === 'text'">{{ pc.value }}</span>
              <a v-if="pc.type === 'url'" :href="pc.value" target="_blank">{{
                pc.value
              }}</a>
            </template>
          </div>
          <div class="comment_footer caption mt-3">
            <div v-if="commentRec.groupChanged" class="d-flex">
              <div>
                <v-icon small color="blue-grey">info</v-icon>
              </div>
              <div class="ml-1" style="padding-top: 1px">
                担当を<span v-if="commentRec.groupCd"
                  >「{{ getGroupName(commentRec.groupCd) }}」</span
                >
                <span v-else>「未設定」</span>に変更しました。
              </div>
            </div>
            <div v-if="commentRec.workflowStateChanged" class="d-flex">
              <div>
                <v-icon small color="blue-grey">info</v-icon>
              </div>
              <div class="ml-1" style="padding-top: 1px">
                <span>
                  状態を「{{
                    getProposalWfStatusName(commentRec.workflowState)
                  }}」に変更しました。</span
                >
              </div>
            </div>
          </div>
        </div>
      </v-sheet>

      <div class="mt-2" v-if="canComment">
        <!-- コメントボタン -->
        <a v-on:click.prevent="onNewCommentClick">
          <v-icon>sms</v-icon>コメント　</a
        >
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import { DateTime, Interval } from "luxon";
import Common from "@/components/Common";
import IntersectionObserver from "@/components/IntersectionObserver";
export default {
  mixins: [Common],
  props: {
    proposalId: Number,
    canEdit: Boolean,
  },
  components: {
    IntersectionObserver,
  },
  mounted: function () {},
  destroyed() {},
  data() {
    return {};
  },
  computed: {
    storeName() {
      //return this.$store.getters.isAuthenticated
      return this.$store.getters["ImprovementProposal/name"];
    },
    proposalRecord() {
      //return this.$store.getters["ImprovementProposal/myRecords"];
      let map = this.$store.state.ImprovementProposal.recordMap;
      return map[this.proposalId];
    },
    canComment() {
      return this.proposalRecord.state == 1; // 下書き、削除済みの場合はコメント不可
    },
  },
  methods: {
    onEditClick() {
      let propId = this.proposalId;
      this.$emit("edit", propId);
    },
    onNewCommentClick() {
      let propId = this.proposalId;
      this.$emit("newcomment", {
        proposalId: propId,
        commentId: 0,
      });
    },
    onEditCommentClick(commentId) {
      let propId = this.proposalId;
      this.$emit("editcomment", {
        proposalId: propId,
        commentId: commentId,
      });
    },
    checkCanEdit(commentRec) {
      let userUid = this.$store.getters.userUid;
      return userUid == commentRec.commentUserId;
    },
    onEntryIntersected(obj) {
      console.log("onEntryIntersected");
      let propId = this.proposalId;
      this.$emit("read", {
        proposalId: propId,
      });
      obj.read_event_fired = true;
    },
  },
};
</script>
