<script>
import { DateTime, Interval } from "luxon";
import ImprovementProposalAPI from "@/apis/ImprovementProposalAPI";

export default {
  mixins: [],
  props: {
    proposalId: Number,
  },
  computed: {
    isNewProposal: function () {
      return this.proposalId == -1;
    },
    canDraftSave: function () {
      return this.isNewProposal || this.editData.state == 2; // 状態が新規 or 下書きだったら下書き保存OK
    },
    canDelete: function () {
      return this.proposalId > 0;
    },
    storeName() {
      //return this.$store.getters.isAuthenticated
      return this.$store.getters["ImprovementProposal/name"];
    },
    myRecords() {
      //return this.$store.getters["ImprovementProposal/myRecords"];
      return this.$store.state.ImprovementProposal.myRecords;
    },
    everyoneRecords() {
      return this.$store.state.ImprovementProposal.everyoneRecords;
      //return this.$store.getters["ImprovementProposal/everyoneRecords"];
    },
    groupsWithBlank() {
      return this.$store.getters.groupsWithBlank;
    },
  },
  data() {
    return {
      loading: false,
      isValid: true,
      editData: {
        id: null,
        title: "",
        content: "",
        files: [],
        state: 0,
        groupCd: "",
        wfState: 0,
      },
      wfStateList: [
        {
          title: "",
          value: 0,
        },
        {
          title: "提案",
          value: 1,
        },
        {
          title: "上長確認済",
          value: 3,
        },
        {
          title: "公開",
          value: 5,
        },
        {
          title: "却下",
          value: 8,
        },
        {
          title: "終了",
          value: 9,
        },
      ],
    };
  },
  methods: {
    async fetchData() {
      let self = this;
      self.loading = true;
      if (this.proposalId > 0) {
        //let map = this.$store.state.ImprovementProposal.recordMap;
        //let curRec = map[this.proposalId];
        // ↓ サーバから最新の状態を求める
        let curRec = await this.$store.dispatch(
          "ImprovementProposal/fetchProposal",
          this.proposalId
        );

        this.editData.title = curRec.title;
        this.editData.content = curRec.content;
        this.editData.state = curRec.state;
        this.editData.wfState = curRec.workflowState;
        this.editData.groupCd = curRec.groupCd;
      } else {
        this.editData.title = "";
        this.editData.content = "";
        this.editData.state = 0;
      }
      self.loading = false;
    },

    async save(isDraft) {
      // 入力値を保存する
      let store = this.$store;

      let title = this.editData.title;
      let content = this.editData.content;
      let state = isDraft ? 2 : 1;
      let wfState = this.editData.wfState;
      let groupCd = this.editData.groupCd;

      if (this.proposalId > 0) {
        // 更新時
        await ImprovementProposalAPI.putProposal(
          this.proposalId,
          title,
          content,
          state,
          wfState,
          groupCd
        );
        // サーバから最新情報を取得
        store.dispatch("ImprovementProposal/fetchProposal", this.proposalId);
      } else {
        // 新規登録時
        let newPropId = await ImprovementProposalAPI.postProposal(
          title,
          content,
          state
        );
        console.log("postProposal result", newPropId);
        store.dispatch("ImprovementProposal/fetchProposal", newPropId);
      }

      this.$router.push({ path: "/improveprop/", query: { tabno: 1 } });
    },
    async deleteProposal() {
      // 提案を削除
      if (this.proposalId > -1) {
        await this.$store.dispatch(
          "ImprovementProposal/deleteProposal",
          this.proposalId
        );
      }

      // 私の改善提案画面へ戻る
      this.$router.replace({ path: "/improveprop/", query: { tabno: 1 } });

      return true;
    },
  },
};
</script>
// this.$store.commit('moduleA/message', text)
