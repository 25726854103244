<template>
  <v-container class="proposal_view">
    <div><span class="title">改善目安箱</span></div>

    <v-tabs v-model="selectedTab">
      <v-tab>みんなのアイデア</v-tab>
      <v-tab>わたしのアイデア</v-tab>
    </v-tabs>
    <div class="py-2"></div>
    <div v-show="selectedTab == 0">
      <v-row>
        <v-col
          v-for="propId in everyoneRecords"
          :key="propId"
          xs="12"
          sm="12"
          md="12"
          lg="12"
          xl="12"
        >
          <ProposalCard
            :proposalId="propId"
            :canEdit="false"
            @newcomment="onNewCommentClick"
            @editcomment="onEditCommentClick"
            @read="onEntryRead"
          ></ProposalCard>
        </v-col>

        <v-col cols="4" xs="6" sm="6" md="3" lg="3" xl="3">
          <!-- 次を読み込むオブザーバ  -->
          <IntersectionObserver
            v-if="nextFetchDateTime"
            @intersect="fetchNext"
          />
        </v-col>
      </v-row>

      <loading-view :loading="loading" />
    </div>
    <div v-show="selectedTab == 1">
      <div class="pl-3 pb-3">
        <v-btn color="primary" v-on:click.prevent="onNewProposalClick">
          新規登録
        </v-btn>
      </div>

      <v-row>
        <v-col v-for="propId in myRecords" :key="propId" cols="12">
          <ProposalCard
            :proposalId="propId"
            @edit="onEdit"
            @newcomment="onNewCommentClick"
            @editcomment="onEditCommentClick"
            @read="onEntryRead"
            :canEdit="true"
          ></ProposalCard>
        </v-col>
      </v-row>

      <loading-view :loading="loading" />
    </div>

    <ConfirmDialog ref="deleteConfirmDialog"></ConfirmDialog>
    <ProposalCommentDialog
      ref="proposalCommentDialogRef"
    ></ProposalCommentDialog>
  </v-container>
</template>

<script>
import { DateTime, Interval } from "luxon";
import Common from "@/components/Common";
import DateInputField from "../../components/controls/DateInputField";
import ConfirmDialog from "../../components/dialogs/ConfirmDialog";
import ProposalCard from "./parts/ProposalCard";
import model from "./models";
import ProposalCommentDialog from "./Dialogs/ProposalCommentDialog";

export default {
  mixins: [Common, model],
  components: {
    DateInputField,
    ConfirmDialog,
    ProposalCard,
    ProposalCommentDialog,
  },
  mounted: function () {
    this.fetchData();
    this.proposalCommentDialogRef = this.$refs.proposalCommentDialogRef;
  },
  destroyed() {},
  watch: {},
  data() {
    return {
      loading: false,

      editDlgDisplay: false,

      editData: {
        isValid: true,
        id: null,
        content: "",
      },
      validateRules: {
        contentRules: [
          (v) => !!v || "内容は必須項目です",
          (v) =>
            (v && v.length <= 500) || "内容は500文字以内で入力してください",
        ],
      },

      nextFetchDateTime: null,
      proposalCommentDialogRef: null,
    };
  },
  computed: {
    canDelete: function () {
      return !!this.editData.id;
    },
  },
  methods: {
    fetchNext() {
      console.log("fetchNext called." + this.nextFetchDateTime);
    },

    makeData(responseData, records) {
      //console.log(responseData);

      let dt = null;

      responseData.forEach((rec) => {
        records.push({
          id: rec.id,
          content: rec.content,
          create_at: rec.create_at,
          post_user_id: rec.post_user_id,
          post_staff_cd: rec.post_staff_cd,
          design_cd: rec.design_cd,
          is_unread: rec.is_unread,
          user_name: this.getUserName(rec.post_staff_cd),
          read_event_fired: false,
        });

        dt = rec.create_at;
      });

      // 最終日時を返す
      return dt;
    },

    async onEntryRead(v) {
      console.log("onEntryRead", v);
      let proposalId = v.proposalId;
      await this.$store.dispatch("ImprovementProposal/makeAsRead", proposalId);
    },

    onNewProposalClick() {
      //let path = this.$router.currentRoute.path;
      //let url = this.buildUrl(path, { dt: this.reportDate });
      this.$router.push({ path: "/improveprop/_new/edit", query: {} });
    },

    onEdit(v) {
      //alert("emit edit " + v);
      this.$router.push({ path: "/improveprop/" + v + "/edit", query: {} });
    },

    async onNewCommentClick(data) {
      let proposalId = data.proposalId;
      let commentId = 0;
      let options = {};
      let dlgResult = await this.proposalCommentDialogRef.open(
        proposalId,
        commentId,
        options
      );
      if (dlgResult > -1) {
        console.log("--yes");
        this.$store.dispatch("ImprovementProposal/refreshProposal", proposalId);
      } else {
        console.log("--no");
      }
    },
    async onEditCommentClick(data) {
      let proposalId = data.proposalId;
      let commentId = data.commentId;

      let options = {};
      let dlgResult = await this.proposalCommentDialogRef.open(
        proposalId,
        commentId,
        options
      );
      console.log("dlgResult", dlgResult);
      if (dlgResult > -1) {
        console.log("--yes");
        // 改善提案情報を丸ごとリフレッシュ
        this.$store.dispatch("ImprovementProposal/refreshProposal", proposalId);
      } else {
        console.log("--no");
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
