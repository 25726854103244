import axios from 'axios';
import Util from '../Util'


class ImprovementProposalAPI {

    constructor() {
        this.apiHost = process.env.VUE_APP_API_HOST;
        this.basePath = "/api/impr-proposals";
    }

    /**
     * 改善提案Noのリストを取得する
     * @returns 改善提案Noのリスト
     */
    list(){

        let path = this.basePath;

        let endpoint =  Util.combineUrl(this.apiHost,path);

        return axios.get(endpoint).then(response =>  response.data)
    }


    getProposal(propId){

        let path = this.basePath + "/" + propId;
        // new Date().getTime()
        
        let endpoint =  Util.combineUrl(this.apiHost,path);
        return axios.get(endpoint).then(response =>  response.data)
    }


    postProposal(title, content, state) {
        
        let data = {
            title, content, state
        }
      
        let path = this.basePath
        let endpoint =  Util.combineUrl(this.apiHost,path);
        return axios.post(endpoint, data).then(response =>  response.data)
    }

    putProposal(propId, title, content, state, wfState, groupCd) {     
        let data = {
            title, content, state, wfState, groupCd
        }
        console.log("putProposal data", data);

        let path = this.basePath + "/" + propId;
        let endpoint =  Util.combineUrl(this.apiHost,path);
        return axios.put(endpoint, data).then(response => response.data)        
    }


    deleteProposal(propId){

        let path = this.basePath + "/" + propId;
        let endpoint =  Util.combineUrl(this.apiHost,path);
        return axios.delete(endpoint).then(response =>  response.data)
    }

    readProposal(propId) {     
        let path = this.basePath + "/" + propId + "/read" ;
        let endpoint =  Util.combineUrl(this.apiHost,path);
        return axios.put(endpoint).then(response => response.data)        
    }


    getComment(proposalId,commentId) {
    
        let path = this._makeCommentPath(proposalId,commentId);
        let endpoint =  Util.combineUrl(this.apiHost,path);
        return axios.get(endpoint).then(response =>  response.data)
    }

    postComment(proposalId,content, wfState,groupCd) {
        
        let data = {
            content, wfState,groupCd
        }
      
        let path = this._makeCommentPath(proposalId);
        let endpoint =  Util.combineUrl(this.apiHost,path);
        return axios.post(endpoint, data).then(response =>  response.data)
    }

    putComment(proposalId,commentId,content, wfState,groupCd) {
        
        let data = {
            content, wfState,groupCd
        }
      
        let path = this._makeCommentPath(proposalId,commentId);
        let endpoint =  Util.combineUrl(this.apiHost,path);
        return axios.put(endpoint, data).then(response =>  response.data)
    }

    deleteComment(proposalId,commentId) {             
        let path = this._makeCommentPath(proposalId,commentId);
        let endpoint =  Util.combineUrl(this.apiHost,path);
        return axios.delete(endpoint).then(response =>  response.data)
    }

    _makeCommentPath(proposalId,commentId) {
        let path = this.basePath + "/" + proposalId + "/comments";
        if (commentId) {
            path += "/" + commentId;
        }
        return path;
    }

}

let _api = new ImprovementProposalAPI();

export default _api;

