import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"proposal_view"},[_c('div',[_c('span',{staticClass:"title"},[_vm._v("改善目安箱")])]),_c(VTabs,{model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_c(VTab,[_vm._v("みんなのアイデア")]),_c(VTab,[_vm._v("わたしのアイデア")])],1),_c('div',{staticClass:"py-2"}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedTab == 0),expression:"selectedTab == 0"}]},[_c(VRow,[_vm._l((_vm.everyoneRecords),function(propId){return _c(VCol,{key:propId,attrs:{"xs":"12","sm":"12","md":"12","lg":"12","xl":"12"}},[_c('ProposalCard',{attrs:{"proposalId":propId,"canEdit":false},on:{"newcomment":_vm.onNewCommentClick,"editcomment":_vm.onEditCommentClick,"read":_vm.onEntryRead}})],1)}),_c(VCol,{attrs:{"cols":"4","xs":"6","sm":"6","md":"3","lg":"3","xl":"3"}},[(_vm.nextFetchDateTime)?_c('IntersectionObserver',{on:{"intersect":_vm.fetchNext}}):_vm._e()],1)],2),_c('loading-view',{attrs:{"loading":_vm.loading}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedTab == 1),expression:"selectedTab == 1"}]},[_c('div',{staticClass:"pl-3 pb-3"},[_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.onNewProposalClick.apply(null, arguments)}}},[_vm._v(" 新規登録 ")])],1),_c(VRow,_vm._l((_vm.myRecords),function(propId){return _c(VCol,{key:propId,attrs:{"cols":"12"}},[_c('ProposalCard',{attrs:{"proposalId":propId,"canEdit":true},on:{"edit":_vm.onEdit,"newcomment":_vm.onNewCommentClick,"editcomment":_vm.onEditCommentClick,"read":_vm.onEntryRead}})],1)}),1),_c('loading-view',{attrs:{"loading":_vm.loading}})],1),_c('ConfirmDialog',{ref:"deleteConfirmDialog"}),_c('ProposalCommentDialog',{ref:"proposalCommentDialogRef"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }