<script>
import { DateTime, Interval } from "luxon";

export default {
  mixins: [],
  props: {
    tabno: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    storeName() {
      //return this.$store.getters.isAuthenticated
      return this.$store.getters["ImprovementProposal/name"];
    },
    myRecords() {
      //return this.$store.getters["ImprovementProposal/myRecords"];
      //return this.$store.state.ImprovementProposal.myRecords;
      return this.$store.getters["ImprovementProposal/myRecords"];
    },
    everyoneRecords() {
      return this.$store.state.ImprovementProposal.proposalIds;
      //return this.$store.getters["ImprovementProposal/myRecords"];
    },
  },
  mounted: function () {
    let userUid = this.$store.getters.userUid;
    this.$store.commit("ImprovementProposal/setMyUserId", userUid);

    if (this.selectedTab != this.tabno) {
      console.log("mounted tabno", this.tabno);
      this.selectedTab = this.tabno;
    }
  },
  data() {
    return {
      selectedTab: 0,
    };
  },
  watch: {
    selectedTab: function (newVal) {
      let path = this.$router.currentRoute.path;
      //let url = this.buildUrl(path, { tabno: newVal });
      //this.$router.replace(url);

      if (newVal != this.tabno) {
        this.$router.replace({ path: path, query: { tabno: newVal } });
      }
    },
  },
  methods: {
    fetchData() {
      let self = this;
      let store = this.$store;

      this.loading = true;

      store.dispatch("ImprovementProposal/fetchList", {});

      this.loading = false;
    },
  },
};
</script>
// this.$store.commit('moduleA/message', text)
