<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
    @keydown.esc="onCommentDlgCancelClick"
  >
    <v-card v-if="loading">
      <v-card-text>
        <div class="pa-5 mt-10">
          <loading-view :loading="true" />
        </div>
      </v-card-text>
    </v-card>
    <v-card v-else>
      <v-card-title class="popup-header">
        <span class="headline">コメント</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-textarea
                counter
                label="コメント"
                required
                v-model="message"
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="mr-2" v-if="canChangeGroup">
              <v-select
                :items="groupsWithBlank"
                label="担当グループ"
                item-text="group_name"
                item-value="group_cd"
                v-model="groupCd"
              ></v-select>
            </v-col>
            <v-col class="ml-2" v-if="canChangeWfState">
              <v-select
                :items="wfStateList"
                label="状態"
                item-text="title"
                item-value="value"
                v-model="wfState"
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn
          v-show="!!this.commentId && this.canDelete"
          color="secondary"
          text
          @click.native="onCommentDlgDeleteClick"
          >削除</v-btn
        >
        <div class="flex-grow-1"></div>
        <v-btn color="primary" @click.native="onCommentDlgSaveClick"
          >保存</v-btn
        >
        <v-btn color="grey lighten-2" @click.native="onCommentDlgCancelClick"
          >キャンセル</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import Common from "@/components/Common";
import DialogMoveSupport from "@/components/DialogMoveSupport";
import ImprovementProposalAPI from "@/apis/ImprovementProposalAPI";
import RoleSupport from "@/components/RoleSupport";
import constants from "@/constants.js";

export default {
  mixins: [Common, DialogMoveSupport, RoleSupport],
  components: {},
  props: {},
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      latest: false,
      loading: false,

      message: "",
      staffCd: null,
      proposalId: null,
      commentId: null,
      commentUserId: "",

      groupCd: "",
      wfState: 0,

      proposalWfState: 1,

      wfStateList: [
        {
          title: "提案",
          value: 1,
        },
        {
          title: "上長確認済",
          value: 3,
        },
        {
          title: "公開",
          value: 5,
        },
        {
          title: "却下",
          value: 8,
        },
        {
          title: "終了",
          value: 9,
        },
      ],

      options: {
        color: "primary",
        width: 290,
      },
    };
  },
  computed: {
    groupsWithBlank() {
      return this.$store.getters.groupsWithBlank;
    },
    canChangeGroup() {
      return this.latest;
    },
    canChangeWfState() {
      return this.latest;
    },
    canEdit() {
      let userUid = this.$store.getters.userUid;
      return this.commentId == 0 || userUid == this.commentUserId;
    },
    canDelete() {
      return this.commentId > 0 && this.latest && this.canEdit;
    },
  },
  watch: {
    dialog: function (val) {
      this.setupMoveEvents(this.dialog);
    },
  },
  methods: {
    open(proposalId, commentId, options) {
      console.log("open", { proposalId, commentId });
      this.proposalId = proposalId;
      this.commentId = commentId;
      this.options = Object.assign(this.options, options); // 使っていないけど一応

      this.fetchData();

      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    async fetchData(curGroupCd, curWfState) {
      this.loading = true;

      let map = this.$store.state.ImprovementProposal.recordMap;
      let propRec = map[this.proposalId];
      this.proposalWfState = propRec.workflowState;

      if (this.commentId > 0) {
        let commentData = await ImprovementProposalAPI.getComment(
          this.proposalId,
          this.commentId
        );
        this.message = commentData.content;
        this.groupCd = commentData.groupCd;
        this.wfState = commentData.workflowState;
        this.latest = commentData.isLatest;
        this.commentUserId = commentData.commentUserId;
      } else {
        this.message = "";
        this.groupCd = propRec.groupCd;
        this.wfState = this.proposalWfState;
        this.latest = true;
        this.commentUserId = "";
      }
      this.filterStatus();
      this.loading = false;
    },

    filterStatus() {
      //WFステータスは現在の状態、ユーザの権限によって選択項目を切り替える
      let mst = constants["improvementProposalAPIWfStatuses"];

      // 現在の状態によるフィルタ
      let nextStatusMap = {
        1: [1, 3, 5],
        3: [3, 5, 8],
        5: [5, 6, 8],
        6: [6, 8, 9],
        8: [8],
        9: [9],
      };

      // 権限によるフィルタ
      let allowedStatusMap = {
        一般: [1, 6, 9],
        管理者: [1, 3, 6, 8, 9],
        人材育成: [1, 3, 5, 6, 8, 9],
      };

      let role = "一般";
      if (this.hasRole("IMPR_PROP_MANAGER")) {
        role = "人材育成";
      } else if (this.hasRole("manager")) {
        role = "管理者";
      }

      let list1 = nextStatusMap[this.proposalWfState];
      let list2 = allowedStatusMap[role];

      // それぞれのフィルタのAnd(Intersection、積集合)をとる
      let codelist = [...list1].filter((element) => list2.includes(element));

      // 現在の値が選択肢に含まれていなかったら最初の要素として追加する
      if (!codelist.includes(this.wfState)) {
        codelist.unshift(this.wfState);
      }

      // CDからデータのオブジェクトを取得してリスト化
      let resultList = codelist.map((cd) => mst[cd]);

      this.wfStateList.splice(0);
      this.wfStateList.push(...resultList);
    },

    async onCommentDlgSaveClick() {
      let self = this;
      // commentId
      // (proposalId,content, wfState,groupCd)
      let proposalId = this.proposalId;
      let content = this.message;
      let wfState = this.wfState;
      let groupCd = this.groupCd;

      await this.checkAuthToken();
      if (this.commentId == 0) {
        await ImprovementProposalAPI.postComment(
          proposalId,
          content,
          wfState,
          groupCd
        );
      } else {
        await ImprovementProposalAPI.putComment(
          proposalId,
          this.commentId,
          content,
          wfState,
          groupCd
        );
      }

      this.resolve(1);
      this.dialog = false;
    },

    onCommentDlgCancelClick() {
      this.resolve(-1);
      this.dialog = false;
    },

    async onCommentDlgDeleteClick() {
      let self = this;
      let proposalId = this.proposalId;
      let commentId = this.commentId;

      await ImprovementProposalAPI.deleteComment(proposalId, commentId);
      this.resolve(9);
      this.dialog = false;
    },
  },
};
</script>
