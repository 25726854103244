<template>
  <v-container class="thanks-view">
    <div>
      <span class="title"
        >改善提案 <span v-if="isNewProposal">新規登録</span
        ><span v-else>編集</span></span
      >
    </div>

    <div v-if="loading" class="pa-5 mt-10">
      <loading-view :loading="true" />
    </div>

    <v-form v-if="!loading" ref="formEdit" v-model="isValid" lazy-validation>
      <v-row dense>
        <v-col>
          <v-text-field
            label="件名"
            placeholder=""
            v-model="editData.title"
            :counter="50"
            maxlength="50"
            :rules="validateRules.titleRule"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col>
          <v-textarea
            name="input-7-1"
            label="提案内容"
            value=""
            hint=""
            v-model="editData.content"
            auto-grow
            :counter="2000"
            maxlength="2000"
            :rules="validateRules.contentRules"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <div class="d-flex flex-row mt-1">
            <span class="caption">担当G：</span>
            <div class="mr-3 caption">
              <span v-if="editData.groupCd">{{
                getGroupName(editData.groupCd)
              }}</span>
              <span v-else>未設定</span>
            </div>

            <span class="caption">状態：</span>
            <div class="caption">
              <span v-if="editData.wfState">
                {{ getProposalWfStatusName(editData.wfState) }}</span
              >
              <span v-else>未設定</span>
            </div>
          </div>
        </v-col>
      </v-row>
      <!--
      <v-row dense v-if="!isNewProposal">
        <v-col class="mr-2">
          <v-select
            :items="groupsWithBlank"
            label="担当グループ"
            item-text="group_name"
            item-value="group_cd"
            v-model="editData.groupCd"
          ></v-select>
        </v-col>
        <v-col class="ml-2">
          <v-select
            :items="wfStateList"
            label="状態"
            item-text="title"
            item-value="value"
            v-model="editData.wfState"
          ></v-select>
        </v-col>
      </v-row>
      -->

      <v-divider class="mb-3 mt-3"></v-divider>

      <v-row dense>
        <v-col class="pl-10">
          <v-btn
            v-on:click.prevent="onSaveBtnClick"
            color="primary"
            class="mr-3 mb-3"
            >保存</v-btn
          >
          <v-btn
            v-if="canDraftSave"
            v-on:click.prevent="onDraftSaveBtnClick"
            class="mr-3 mb-3"
          >
            下書保存
          </v-btn>
          <v-btn v-on:click.prevent="onCancelBtnClick" class="mr-3 mb-3"
            >キャンセル</v-btn
          >
        </v-col>
        <v-col align="right">
          <v-btn v-if="canDelete" v-on:click.prevent="onDeleteBtnClick"
            >削除</v-btn
          >
        </v-col>
      </v-row>
    </v-form>

    <ConfirmDialog ref="deleteConfirmDialog"></ConfirmDialog>
  </v-container>
</template>

<script>
import { DateTime, Interval } from "luxon";
import Common from "@/components/Common";
import ConfirmDialog from "@/components/dialogs/ConfirmDialog";
import model from "./models";

export default {
  mixins: [Common, model],
  components: {
    ConfirmDialog,
  },
  mounted: function () {
    this.fetchData();
  },
  destroyed() {},
  props: {},
  watch: {},
  data() {
    return {
      loading: false,

      selected_tab: 0,

      editDlgDisplay: false,

      validateRules: {
        titleRule: [
          (v) => !!v || "件名は必須項目です",
          (v) => (v && v.length <= 50) || "件名は50文字以内で入力してください",
        ],
        contentRules: [
          (v) => !!v || "提案内容は必須項目です",
          (v) =>
            (v && v.length <= 2000) || "内容は2000文字以内で入力してください",
        ],
      },
      isEditMode: true,
      nextFetchDateTime: null,
    };
  },
  computed: {},
  methods: {
    onSaveBtnClick() {
      console.log("onSaveBtnClick");
      this.save(false);
    },
    onDraftSaveBtnClick() {
      console.log("onDraftSaveBtnClick");
      this.save(true);
    },
    onDeleteBtnClick() {
      console.log("onDeleteBtnClick");
      if (this.deleteProposal()) {
        this.showMessageToast("改善提案を削除しました。");
      }
    },
    onCancelBtnClick() {
      console.log("onCancelBtnClick");
      this.$router.back();
    },
    onFileDeleteClick() {
      console.log("onFileDeleteClick");
    },
    onFileDrop() {
      console.log("onFileDrop");
    },
  },
};
</script>
<style lang="scss" scoped></style>
